import React from "react"
import Layout from "../../../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Col, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import styled from "styled-components"
import PartnerCarousel from "../../../components/PartnerCarousel"
import PeaceOfMindGuarantee from "../../../components/PeaceOfMindGuarantee"
import SectionTitle from "../../../components/SectionTitle"
import Services from "../../../components/Services"
import { Banner } from "../../../components/Banner"
import BusinessOffers from "../../../components/business/BusinessOffers"
import SEO from "../../../components/seo"

export default function Audiovisual() {
  const data = useStaticQuery(graphql`
    query businessAudiovisual {
      InteractiveTechnology: file(
        relativePath: { eq: "services/AdobeStock_234479733.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      DigitalSignage: file(
        relativePath: { eq: "services/AdobeStock_227933099.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      SoundSystems: file(
        relativePath: { eq: "services/AdobeStock_184497717.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      Maintenance: file(
        relativePath: { eq: "services/AdobeStock_308707407.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      VideoConferencing: file(
        relativePath: { eq: "services/AdobeStock_335250034.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      BannerImage: file(
        relativePath: { eq: "banner/AdobeStock_297400808.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const services = [
    {
      title: "Interactive Technology",
      description:
        "Enhance your conference or meeting room with the ability to use the powerful software within your computer, including Video Conferencing, Presenting, Interactive Mind Mapping, Annotation and much more",
      image: data.InteractiveTechnology.childImageSharp.fixed,
    },
    {
      title: "Digital Signage",
      description: "Display Screens, Outdoor Display Screens, Video Walls",
      image: data.DigitalSignage.childImageSharp.fixed,
    },
    {
      title: "Sound Systems",
      description: "Soundfield, PA Systems, Speakers, Amps, Hall Audio System",
      image: data.SoundSystems.childImageSharp.fixed,
    },
    {
      title: "Video Conferencing",
      description: "Laptops, Webcams, Microphones, Speakerphones",
      image: data.VideoConferencing.childImageSharp.fixed,
    },
    {
      title: "Maintenance",
      description:
        "Extended Warranties, Telephone Support, Remote Support, Onsite Support, Installation",
      image: data.Maintenance.childImageSharp.fixed,
    },
  ]

  return (
    <Layout headerType="business">
      <SEO title="Compulease · Audiovisual Business" />
      <BusinessOffers />
      <Container>
        <Row>
          <Col>
            <SectionTitle
              title="Audiovisual"
              description={
                <p>
                  Compulease™ understand that every requirement is unique and
                  that “one size doesn’t fit all”. Our “design to
                  implementation” service, starts with getting an in-depth
                  understanding of what you are looking to achieve and within
                  budget. You can be sure you’ll receive a leading brand
                  solution that will deliver reliability, performance and
                  quality.
                </p>
              }
            />
          </Col>
        </Row>
        <FurtherBenefits>
          <Row>
            <Col>
              <h3>
                Compulease supplies an unrivalled and complete range of Plasma
                screens and LCD screens to rent, from all the leading
                manufacturers and will provide you with expert advice
              </h3>
              <p>
                So whether you require a plasma display to demonstrate your
                corporate message in reception, display up to the minute news
                messages, equip your training room or simply use the plasma to
                show a sports or current event, we can find a tax-free solution
                for your business.
              </p>
              <Row>
                <Col>
                  <Center>
                    <ul>
                      {[
                        "Variety of sizes and brands to choose from",
                        "LCD TVs starting from as little as £4.99 a week",
                        "42” Plasma Screens starting from only £8.99 a week",
                      ].map((item, index) => (
                        <li key={index}>
                          <FontAwesomeIcon icon={faCheckCircle} />
                          {item}
                        </li>
                      ))}
                    </ul>
                  </Center>
                </Col>
              </Row>
            </Col>
          </Row>
        </FurtherBenefits>
      </Container>
      <Banner
        image={data.BannerImage.childImageSharp.fluid}
        title="Compulease can deliver a solution that’s both dynamic and affordable"
        link={{
          text: "Get in Touch",
          to: "/business/ContactUs/",
        }}
      />
      <Services title="Solutions we provide" services={services} />
      <Container>
        <PeaceOfMindGuarantee />
        <PartnerCarousel type="business" />
      </Container>
    </Layout>
  )
}

const Center = styled.div`
  display: flex;
  justify-content: center;
`

const FurtherBenefits = styled.div`
  margin: 0rem 0 2rem 0;
  h3 {
    text-align: center;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 1rem 0;
    padding-left: 0;
    li {
      font-size: 16px;
      padding-left: 25px;
      list-style: none;
      position: relative;
      padding-bottom: 10px;
      svg {
        position: absolute;
        left: 0px;
        top: 5px;
        color: #27ae60;
      }
    }
  }
`
